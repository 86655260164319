<template>
    <VueFinalModal
      :model-value="localShowImportModal"
      @update:model-value="updateShowImportModal"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="modal-header">
        <h5 class="modal-title">Import Executives from CSV</h5>
        <p class="text-start">Only 3 cols with header first row: First Name, Last Name, Email</p>
        <button class="modal-close" @click="updateShowImportModal(false)">x</button>
      </div>
      <div class="modal-body">
        <input
          type="text"
          v-model="csvUrl"
          placeholder="Enter CSV File URL"
          class="form-control mb-3"
        />
        <button class="btn btn-primary" @click="importCsv">Import</button>
        <p v-if="importStatus" :class="importStatusClass">{{ importStatus }}</p>
      </div>
    </VueFinalModal>
  </template>
  
  <script>
  import { ref, computed, watchEffect } from "vue";
  import { useMutation } from "@urql/vue";
  import { VueFinalModal } from 'vue-final-modal';
  import { IMPORT_BULK_EMAILS_FROM_EXTERNAL_RESOURCE } from "../graphql/bulk_emails_to_execs";
  
  export default {
    name: "ImportExecutivesModal",
    components: {
    VueFinalModal,
  },
    props: {
      showImportModal: {
        type: Boolean,
        required: true,
      },
      campaignId: {
        type: Number,
        required: true,
      },
    },
    emits: ['updateShowImportModal'],
    setup(props, { emit }) {
      const csvUrl = ref("");
      const importStatus = ref("");
      const { executeMutation } = useMutation(IMPORT_BULK_EMAILS_FROM_EXTERNAL_RESOURCE);
  
      // Local state to handle modal visibility
      const localShowImportModal = ref(props.showImportModal);
  
      watchEffect(() => {
        //console.log("IM:",props.showImportModal);
        localShowImportModal.value = props.showImportModal;
      });
  
      // Emit changes to parent
      const updateShowImportModal = (value) => {
        localShowImportModal.value = value;
        emit("updateShowImportModal", value);
      };
  
      const importStatusClass = computed(() => {
        return importStatus.value.includes("Success") ? "text-success" : "text-danger";
      });
  
      const importCsv = async () => {
        if (!csvUrl.value) {
            importStatus.value = "Please provide a valid URL.";
            return;
        }

        try {
            console.log("File Name:", csvUrl.value);
            const response = await fetch(csvUrl.value);

            if (!response.ok) {
                throw new Error(`Failed to fetch CSV file. Status: ${response.status}`);
            }

            const csvData = await response.text();

            // Parse CSV data into rows
            const rows = csvData.split("\n").slice(1); // Skip header row
            const records = rows
            .map((row) => {
                const [execFirstName, execLastName, execEmail] = row.split(",").map((value) => value.trim());
                if (execEmail && execEmail.includes("@")) {
                    return { execFirstName, execLastName, execEmail };
                }
                return null;
            })
            .filter(Boolean);

            if (records.length === 0) {
            importStatus.value = "No valid records to import.";
            return;
            }

            console.log("csvData", records);

            // Call mutation to insert data into the database
            const result = await executeMutation({ records, campaignId: props.campaignId });

            if (result.error) {
            console.log("error::", result);
            importStatus.value = `Error: ${result.error.errors}`;
            } else {
            importStatus.value = "Success: Records imported successfully.";
            csvUrl.value = "";
            }
        } catch (error) {
            console.error("Error importing CSV:", error);
            importStatus.value = `Error: ${error.errors}`;
        }
        };
  
      return {
        localShowImportModal,
        csvUrl,
        importStatus,
        importStatusClass,
        importCsv,
        updateShowImportModal,
      };
    },
  };
  </script>
  
  <style scoped>
  .modal-container {
    max-width: 500px;
  }
  </style>
  