<template>
  <div>
    <BreadcrumbNav :crumbs="breadcrumbs" />
    <!-- Display Success Alert Message -->
    <AlertMessage v-if="isAlertMessageVisible" :message="successMessage" :type="alertType" />


    <!-- Action Buttons -->
    <div class="d-flex justify-content-between align-items-center mb-3">
      <!-- Search Term Input on the Left -->
      <div class="d-flex align-items-center">
        <input 
          type="text" 
          v-model="searchTerm" 
          placeholder="Search..." 
          class="form-control me-2" 
          style="max-width: 300px;"
        />
      </div>

      <!-- Buttons on the Right -->
      <div>
        <button class="btn btn-primary me-2" @click="showSearchFormModal = true">Add More</button>
        <button  v-if="campaign_id" class="btn btn-primary me-2" @click="showImportModal = true">Import from CSV</button>
        <button class="btn btn-secondary me-2" @click="showEmailTemplate = true">Email Template</button>
        <button v-if="emailTemplateFound" class="btn btn-success" @click="onScheduleNow">Schedule</button>
      </div>
    </div>
    <div>CCC: {{ campaign_id }}</div>
    <div v-if="campaign_id">
      
      <ImportBulkEmailExecutivesModal
        :showImportModal="showImportModal"
        :campaignId="campaign_id"
        @updateShowImportModal="updateShowImportModal"
      />
    </div>

    <BulkEmailExecTemplateModal
      :showModal="showEmailTemplate"
      :searchListId="search_list_id"
      :pageTitle="search_list_name"
      :firmId="firm_id"
      :userId="user_id"
      @dbStatusUpdate="dbStatusUpdate"
      @update:showModal="showEmailTemplate = $event"
      
    />

    <!-- Loading State -->
    <div v-if="table.isLoading" class="text-center">Loading...</div>

    <!-- No Records Found Message -->
    <div v-if="!table.isLoading && table.rows.length === 0" class="alert alert-warning text-center">
      <strong>No records found!</strong> Please try adjusting your search.
    </div>

    <!-- Table Component -->
    <VueTableLite
      :rows="table.rows"
      :columns="table.columns"
      :is-loading="table.isLoading"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      :page-size="table.pageSize"
      @do-search="doSearch"
      @is-finished="tableLoadingFinish"
      wrapper-class="w-100"
    />

    <!-- VueFinalModal for 'Add More' -->
    <VueFinalModal
      v-model="showSearchFormModal"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="modal-header">
        <h5 class="modal-title">Search and Add Executives</h5>
        <button class="modal-close" @click="showSearchFormModal = false">x</button>
      </div>
      <div class="modal-body">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search Executive..."
          class="search-bar form-control mb-3"
          @input="executeProfileQuery"
        />
        <div v-for="item in searchQueryFilteredItems" :key="item.id" class="item-container" :class="{ 'item-disabled': addedSearchTempItems.has(item.id) }">
          <div class="p-1">
            {{ item.name.fullName }} | {{ item.experienceAggregate.companyName }} ({{ item.experienceAggregate.currentTitle }})
            <button v-if="!addedSearchTempItems.has(item.id)" class="btn btn-primary btn-sm ms-2" @click="addToTable(item)">Add to Table</button>
          </div>
        </div>
      </div>
    </VueFinalModal>

  </div>
</template>

<script>
import { defineComponent, ref, reactive , watchEffect,watch, onMounted, computed, getCurrentInstance } from 'vue';
import { useQuery, useMutation } from '@urql/vue';
import BreadcrumbNav from '@/components/BreadCrumbNav.vue';
import BulkEmailExecTemplateModal from '@/components/BulkEmailExecTemplateModal.vue';
import AlertMessage from '@/components/AlertMessage.vue';
import { formatDateTime } from '@/components/DateFormatter';
import ImportBulkEmailExecutivesModal from "@/components/ImportBulkEmailExecutivesModal.vue";
import VueTableLite from 'vue3-table-lite';
//import { formatDateTime } from '@/components/DateFormatter';
import { APP_GET_PROFILE_BY_NAME } from '../graphql/search_criteria_list';
import { GET_BULK_EMAIL_EXC_GET_ALL_EXECS_SEARCH_LIST, BULK_EMAIL_EXC_INITIALIZE_EMAIL_SCHEDULER, DELETE_BULK_EMAIL_EXECUTIVE } from '../graphql/bulk_emails_to_execs';
import { useRoute,useRouter } from 'vue-router';
import { VueFinalModal } from 'vue-final-modal';
import { useStore } from 'vuex';
import { useConfirmDialog } from '@/plugins/useConfirmDialog';

export default defineComponent({
  name: 'AppBulkEmailsExecsList',
  components: {
    BreadcrumbNav,
    VueTableLite,
    VueFinalModal,
    AlertMessage,
    BulkEmailExecTemplateModal,
    ImportBulkEmailExecutivesModal
  },
  setup() {
    const successMessage = ref('');
    const alertType = ref('');
    const isAlertMessageVisible = ref(false);
    const isConfirmDialogVisible = ref(false);
    const confirmMessage = ref('');

    const showEmailTemplate = ref(false);
    const emailTemplateFound = ref(false);
    const router = useRouter();
    const route = useRoute();
    const campaign_id = ref(null);
    const showImportModal = ref(false);
    
    const updateShowImportModal = (data) => {
      console.log("Emitted...");
      showImportModal.value = data;
    };
    
    
    const getStatusLabel = (status) => {
      const statusExecutiveLabels = {
        0: 'Pending',
        1: 'Sent',
        2: 'Error'
      };
      return statusExecutiveLabels[status] || 'Pending';
    };
    const confirmDialog = useConfirmDialog();

    const { appContext } = getCurrentInstance();
    const appProfileURL = appContext.config.globalProperties.$AppProfileURL;
    const table = reactive({
      isLoading: false,
      rows: [],
      columns: [
        { label: 'ID', field: 'id', sortable: true },
        {
          label: 'Executive',
          field: 'execId',
          sortable: false,
          display: (row) => {
            // Check if execId is empty or blank
            if (!row.execId || row.execId.trim() === "") {
              return "N/A"; // Display N/A if execId is empty
            }
            // Otherwise, return the link
            return `<a href="${appProfileURL}${row.execId}" target="_blank">Executive</a>`;
          },
        },
        { label: 'First Name', field: 'execFirstName', sortable: true },
        { label: 'Last Name', field: 'execLastName', sortable: true },
        { label: 'Email', field: 'execEmail', sortable: false },
        { label: 'Title', field: 'execTitle', sortable: true },
        { label: 'Company', field: 'execCompany', sortable: false },
        { label: 'Status', field: 'emailStatus', sortable: false, display: (row) => getStatusLabel(row.emailStatus) },
        {
          label: 'Actions',
          field: 'actions',
          display: (row) => (
            `<button type="button" data-id="${row.id}" class="btn btn-danger btn-sm delete-btn">Delete</button>`
          )
        },
      ],
      totalRecordCount: 0,
      pageSize:0,
      sortable: {
        order: 'exec_last_name',
        sort: 'asc',
      },
    });

    const { executeMutation: executeDeleteExecutive } = useMutation(DELETE_BULK_EMAIL_EXECUTIVE);
    const deleteExecutive = async (execId) => {
      const confirmed = await confirmDialog(`Are you sure you want to delete executive with ID ${execId}?`);
      if (!confirmed) return;
      
      try {
        const variables = { execId: parseInt(execId, 10) };
        const result = await executeDeleteExecutive(variables);
        
        if (result.data?.deleteBulkEmailExecutive) {
          table.rows = table.rows.filter(row => row.id !== execId);
          successMessage.value = 'Executive deleted successfully!';
          alertType.value = 'success';
          isAlertMessageVisible.value = true;
        } else {
          successMessage.value = 'Failed to delete executive.';
          alertType.value = 'danger';
          isAlertMessageVisible.value = true;
        }
        setTimeout(() => {isAlertMessageVisible.value = false;}, 3000);
      } catch (error) {
        console.error('Error deleting executive:', error);
      }
    };

    const page = ref(1);
    const firm_name = ref('');
    const firm_id = ref('');
    const search_list_name = ref('');
    const current_page_name = computed(() => {
      const nameParts = [];
      if (firm_name.value) {
        nameParts.push(firm_name.value);
      }
      if (search_list_name.value) {
        nameParts.push(search_list_name.value);
      }
      return nameParts.join(' - ');
    });
    const showConfirmation = ref(false);
    
    const search_list_id = ref(null);
    const breadcrumbs = computed(() => [
      { text: 'Home', routerName: 'HomePage' },
      { text: 'Bulk Emails', routerName: 'AppBulkEmailsExecs' },
      { text: current_page_name.value, routerName: '' },
    ]);
    const statusCampaignLabels = {
        0: 'Draft',
        1: 'Pause',
        2: 'Pending',
        3: 'Reconsider',
        4: 'Approved',
        5: 'Active',
        6: 'Pause',
        7: 'Sent',
      };
    const store = useStore();
    const user_id = computed(() => store.getters['user/getUserId']);
    const searchTerm = ref('');
    const execQueryVariables = ref({
      search_list_id: search_list_id.value,
      user_id: user_id.value,
      page: page.value,
      searchTerm: '',
    });
    const { data: execData, error: execError, executeQuery: fetchExecData } = useQuery({
      query: GET_BULK_EMAIL_EXC_GET_ALL_EXECS_SEARCH_LIST,
      variables: execQueryVariables.value,
      requestPolicy: 'network-only',
      pause: true,
    });
    // Fetch and load table data
    const loadTableData = () => {
      //console.log("TABLE LOAD CALLING ...::",search_list_id.value);
      table.isLoading = true;
      execQueryVariables.value.search_list_id = search_list_id.value;
      //const variables = { ...execQueryVariables.value, search_list_id: search_list_id.value };
      fetchExecData(execQueryVariables).then(() => {
        table.isLoading = false;
      });
    };
    
    watchEffect(() => {
      if (execError.value) {
        console.error('Error fetching exec data:', execError.value);
        //router.push({ name: 'AppBulkEmailsExecs' }); // Redirect on error
      }
    });
    /*watchEffect(() => {
      console.log("showImportModal::",showImportModal.value);
    });
    watch(
      () => search_list_id.value,
      (newVal, oldVal) => {
        if (newVal && newVal !== oldVal){
          loadTableData();
        }
      }
    );
    watchEffect(() => {
      if (campaign_id.value === 0) {
        //router.push({ name: 'AppBulkEmailsCampaign', params: { campaign_id: campaign_id.value } });
      }
    });
    */
    watch(
      () => execData.value, // Watch only the value of execData
      (newVal) => {
        if (newVal) {
          const execResults = newVal.getBulkEmailExcGetAllExecsSearchList;
          if (execResults === null) {
            console.log("No record found from executive data.");
          } else {
            const { items, totalCount, pageSize, firmId, firmName, searchListName, campaignId, campaignActive } = execResults;
            table.rows = items;
            table.totalRecordCount = totalCount;
            table.pageSize = pageSize;
            firm_name.value = firmName;
            firm_id.value = firmId;
            search_list_name.value = searchListName;
            campaign_id.value = campaignId;
            const statusLabel = statusCampaignLabels[campaignActive] || 'Unknown Status';
            document.getElementById('header-title').innerText = `Bulk Emails | ${current_page_name.value} - ${statusLabel}`;
          }
        }
        if (execError.value) {
          console.error('GraphQL Error:', execError.value);
          table.isLoading = false;
        }
      }
    );

    
    const dbStatusUpdate = (data) => {
      emailTemplateFound.value = data;
    };
    
    
    const execsFieldMapping = {
      firmId: "firm_id",
      campaignId: "campaign_id",
      searchListId: "search_list_id",
      execId: "exec_id",
      execFirstName: "exec_first_name",
      execLastName: "exec_last_name",
      execEmail: "exec_email",
      execCompany: "exec_company",
      execTitle: "exec_title",
      emailStatus: "email_status",
      emailStatusDesc: "email_status_desc",
      userId: "user_id",  // Assuming frontend field `userId` maps to `user_id`
      created: "created",
      updated: "updated",
      archiveDate: "archive_date",
    };
    const doSearch = (offset, limit, order, sort) => {
      const backendOrderField = execsFieldMapping[order] || order;
      //console.log("TESTING SEARCHINg...");
      execQueryVariables.value.offset = offset;
      execQueryVariables.value.limit = limit;
      execQueryVariables.value.sort = sort;
      execQueryVariables.value.order = backendOrderField;
      execQueryVariables.value.searchTerm = searchTerm.value;
      //loadTableData();
    };
    watch(
        () => searchTerm.value,
        () => {
          //console.log("Search term updated:", newTerm);
          doSearch(0, table.pageSize, table.sortable.order, table.sortable.sort);
        }
      );
    

    const tableLoadingFinish = () => {
      table.isLoading = false;
    };
    const onScheduleNow = () => {
      router.push({ name: 'AppBulkEmailsCampaign', params: { campaign_id: campaign_id.value } });
    };

    // Fetch items based on search query
    const searchQuery = ref('');
    const searchItems = ref([]);
    const searchQueryFilteredItems = ref([]);
    const showSearchFormModal = ref(false);
    const searchQueryVariables = ref({ search_name_query: searchQuery.value });
    const addedSearchTempItems = ref(new Set());
    const { data: data_profile, executeQuery: executeProfileQuery } = useQuery({
      query: APP_GET_PROFILE_BY_NAME,
      variables: searchQueryVariables.value,
      pause: true,
    });
    watchEffect(() => {
      if (searchQuery.value.trim()) {
        searchQueryVariables.value.search_name_query = searchQuery.value.trim().toLowerCase();
        executeProfileQuery();
      }
    });
    watchEffect(() => {
      if (data_profile.value) {
        if (Array.isArray(data_profile.value.appGetProfileByName)) {
          searchItems.value = data_profile.value.appGetProfileByName;
          searchQueryFilteredItems.value = searchItems.value;
        } else {
          searchItems.value = [];
        }
      }
    });

    // Method to add item to the table
    const { executeMutation: addToEmailScheduler } = useMutation(BULK_EMAIL_EXC_INITIALIZE_EMAIL_SCHEDULER);
    const addToTable = (item) => {
      const itemNEW = {
        execId: item.id,
        execFirstName: item.name.fullName.split(' ')[0],
        execLastName: item.name.fullName.split(' ')[1] || '',
        execTitle: item.experienceAggregate.currentTitle === "Other" 
        ? (item.experienceAggregate.exclusiveTitle ? item.experienceAggregate.exclusiveTitle : item.experienceAggregate.title)
        : item.experienceAggregate.currentTitle,
        execCompany: item.experienceAggregate.companyName,
        execEmail: item.email
      };

      addToEmailScheduler({
        firmId: firm_id.value || null,
        searchListId: search_list_id.value,
        userId: user_id.value,
        campaignId: parseInt(campaign_id.value, 10),
        execs: [
          {
            execId: itemNEW.execId,
            execFirstName: itemNEW.execFirstName,
            execLastName: itemNEW.execLastName,
            execTitle: itemNEW.execTitle,
            execCompany: itemNEW.execCompany,
            execEmail: itemNEW.execEmail
          },
        ],
      })
      .then((response) => {
        if (response.data && response.data.bulkEmailExcInitializeEmailScheduler.success) {
          const lastInsertId = response.data.bulkEmailExcInitializeEmailScheduler.lastInsertIds[0]?.id;
          if (lastInsertId) {
            itemNEW.id = lastInsertId;
            table.rows.unshift(itemNEW);
            addedSearchTempItems.value.add(item.id);
            successMessage.value = `Email ${item.email} was added successfully to the table!`;
            alertType.value = 'success';
          } else {
            successMessage.value = `Email ${item.email} was not added because it already exists.`;
            alertType.value = 'danger';
          }//endif
          isAlertMessageVisible.value = true;
          setTimeout(() => {isAlertMessageVisible.value = false;}, 3000);
        } else {
          console.error(response.data.bulkEmailExcInitializeEmailScheduler.errors);
        }
      })
      .catch((err) => {
        console.error('Error during mutation:', err);
      });
    };

    onMounted(() => {
      search_list_id.value = route.params.list_id;
      loadTableData();
      const storedStatus = JSON.parse(localStorage.getItem('db_status'));
      if (storedStatus !== null) {
        emailTemplateFound.value = storedStatus;
      }
      document.addEventListener('click', (event) => {
        if (event.target.classList.contains('delete-btn')) {
          const execId = event.target.getAttribute('data-id');
          deleteExecutive(execId);
        }
      });

    });

    return {
      breadcrumbs,
      table,
      doSearch,
      tableLoadingFinish,
      onScheduleNow,
      showConfirmation,
      emailTemplateFound,
      showSearchFormModal,
      searchQuery,
      searchQueryFilteredItems,
      addToTable,
      addedSearchTempItems,
      successMessage,
      isAlertMessageVisible,
      alertType,
      showEmailTemplate,
      search_list_id,
      search_list_name,
      firm_id,
      user_id,
      dbStatusUpdate,
      campaign_id,
      formatDateTime,
      getStatusLabel,
      searchTerm,
      deleteExecutive,
      isConfirmDialogVisible,
      confirmMessage,
      showImportModal,
      updateShowImportModal
    };
  },
});
</script>
<style scoped>
  .item-disabled {
    background-color: #e0e0e0;
    color: #888;
  }
</style>